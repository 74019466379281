<template>
<!-- 首页预售和限时抢购 列表页 -->
<div>
<el-col :span="4" class="item"  v-if="productType=='TimeBuy'">
    <div class="imgWrap"  @click="jumpTimeBuyDetail(info.id)">
      <img
        :alt="info.name | defaultName"
          :src="info.images?info.images + picSuffix : defaultImage"
      />
    </div>
    <h3 class="shopName" :title="info.name">{{ info.name | defaultName }}</h3>
    <!-- <p class="msg" :title="info.description">{{info.detail}}{{info.description}}</p> -->
    <p class="msg">抢购数量：{{info.comboTotal}}</p>
     <div class="priceBox">
      <span class="price">
        {{ info.auditUserStatus===0?"***":info.comboPrice  | capitalize }}
      </span>
      <del class="delPrice">{{ info.auditUserStatus===0?"***":info.comboOriginalPrice  | capitalize }}</del>
    </div>
    <p class="msg">进行中</p>
  </el-col>
  <el-col :span="4" class="item"  v-if="productType=='OpenBooking'">
    <div class="imgWrap"  @click="JumpOpenBookingDetail(info.promotionId)">
      <img
        :alt="info.name | defaultName"
        :src="url?url + picSuffix : defaultImage"
      />
      <img  class="AdvanceSaleBg" src="@/assets/AdvanceSale.png" alt="">
      <div class="bottomRightBox">
          预计{{info.presellDeliveryDate }}发货
      </div>
    </div>
    <h3 class="shopName" :title="info.name">{{ info.name | defaultName }}</h3>
    <p class="msg">{{info.specification}}</p>
    <p class="msg" :title="info.manufacturerName">{{info.manufacturerName}}</p>
    <div class="priceBox">
      <!-- <span class="labelBox">
        套餐价
        <div class="arrow-right"></div>
      </span> -->
      <span class="price">
         <!-- {{ info.auditUserStatus===0?"***":info.price  | capitalize }} -->
         ￥{{Number(numPriceArr[numPriceArr.length - 1].price).toFixed(2)}} 起
        </span>
      <del class="delPrice">
         {{ info.auditUserStatus===0?"***":info.originalPrice  | capitalize }}
       </del>
    </div>
      <p class="msg">热销中</p>
  </el-col>
</div>
  
</template>
<style lang="less" scoped>
@import "~style/index.less";
.item {
  background: #fff;
  padding-left:8px !important;
  padding-right:8px!important;
  padding-bottom:10px;

  .imgWrap {
    // padding: 15px 0;
    overflow: hidden;
    position: relative;
    .AdvanceSaleBg{
      display: block;
        width:184px;
        height:184px;
        z-index: 2;
        position:absolute;
        top:0px;
        right:0px;
        &:hover {
          box-shadow: 10px 6px 6px -4px #dedada;
        }
    }
    .bottomRightBox{
      position: absolute;
      bottom:8px;
      right: 0;
      z-index:3;
      width:63.5%;
      color: #FFFFFF;
      font-size:12px;
    }
    img {
      display: inline-block;
      // width:176px;
      // height: 176px;
      width:184px;
      height:184px;
      margin: 0 auto;
      cursor: pointer;
      // &:hover {
      //   box-shadow: 10px 6px 6px -4px #dedada;
      //   border-radius: 7px;
      // }
    }
  }
  .shopName {
    height: 22px;
    color: #333;
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    // overflow:hidden;
    // text-overflow: ellipsis;
    // display: -webkit-box;
    // -webkit-line-clamp:2;
    // -webkit-box-orient: vertical;
  }
  .priceBox {
    margin-top:5px;
    height:26px;
    line-height:26px;
    font-size: 12px;
    color: #999;
    // .labelBox {
    //     display: inline-block;
    //   background: @theme;
    //   padding: 0 5px;
    //   color: #fff;
    //   text-align: center;
    //   line-height:20px;
    //   position: relative;
    //   .arrow-right {
    //         position: absolute;
    //         right: -4px;
    //         width: 6px;
    //         height: 6px;
    //         top: 6px;
    //         background: @theme;
    //         border-top: 2px solid @theme;
    //         border-right: 2px solid @theme;
    //         transform: rotate(45deg);
    //   }
    // }
    .price{
        color: @theme;
        font-size:16px;
        font-weight: bold;
        display: inline-block;
    }
    .delPrice{
        padding-left:10px;
        color: #929598;
        font-size: 12px;
    }
  }
  .msg{
        color: #929598;
        font-size: 12px;
        text-indent: 3px;
        line-height: 20px;
        width: 100%;
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
        cursor: pointer;
    }
}
</style>

<script>
import defaultImage from "@/assets/defaultImage.png";
export default {
  name: "ComboItem",
  data() {
    return {
      picSuffix: localStorage.getItem("productPic"),
      currentIndex: 0,
      //价格框显示隐藏
      status: false,
      num: 0,
      // pics: 1,
      defaultImage: defaultImage,
      promotionMap: "",
      url:'',
      numPriceArr:[],
    };
  },
  props: {
    info: {
      type: Object,
    },
    productType: {
      type: String,
    },
  },
  methods: {
    // 限时购
    jumpTimeBuyDetail(id) {
      // this.$router.push({
      //    name: "ActivityModuleDetail",
      //   query: { id: id,ActivityType:2},
      // });
      const routeData = this.$router.resolve({
         name: "ActivityModuleDetail",
        query: { id: id,ActivityType:2},
      });
      window.open(routeData.href, '_blank');
    },
    // CommonJumpDetail(id,isRecentExpiration) {
    //   this.$router.push({ name: "ProductDetail", query: { id: id,isRecentExpiration:isRecentExpiration<0?0:isRecentExpiration } });
    // },

    // 预售
    JumpOpenBookingDetail(promotionId) {
      // this.$router.push({ name: "ActivityModuleDetail", query: { promotionId: promotionId,ActivityType:1 } });
      const routeData = this.$router.resolve({ name: "ActivityModuleDetail", query: { promotionId: promotionId,ActivityType:1 } });
      window.open(routeData.href, '_blank');
    },
  },
  watch: {
    "info": function(newValue) {
      if (newValue) {
        if(newValue.numPrice && newValue.numPrice!=''){
          this.numPriceArr=JSON.parse(newValue.numPrice)
        }
      }
    },
  }, 
  created() {
    if(this.productType=='OpenBooking'){
      this.url=this.info.pic.split(",")[0]
      if(this.info.numPrice && this.info.numPrice!=''){
        this.numPriceArr=JSON.parse(this.info.numPrice)
      }
    }
    // console.log(this.info);
  },
  updated() {},
};
</script>
